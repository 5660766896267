import styled from 'styled-components';

export const PostGrid = styled.div`
    display: flex;
    height: 100%;
    @media (max-width: 990px){
       flex-direction: column;
    }
`;

export const InfoGrid  = styled.div`
    padding: 20px;
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    width: 50%;
    
    @media (max-width: 990px){
        padding: 10px;
        width: 90%;
        flex-direction: column;
        order: 0;
     }
`;


export const ImageGrid = styled.div`
    display: flex;
    width: 50%;
    @media (max-width: 990px){
        width: 100%;
        flex-grow: 3;
        margin-top: -10%;
        order: 1;
     }
`;