export default[
  {
    id: 0,
    year: "Spring 2023",
    title: "UIL One Act Play",
    description: "Individual award recipients for UIL One Act Play.",
    color: "LimeGreen"
  },
  {
    id: 1,
    year: "Spring 2023",
    title: "UIL One Act Play",
    description: "All group picture receiving advancing play award.",
    color: "Tomato"
  },
  {
    id: 2,
    year: "Spring 2023",
    title: "San Jacinto College Clinic",
    description: "Students traveled San Jacinto College in Houston, TX to perform for clinicians. This opportunity allowed students to receive feedback from professionals before competition.",
    color: "#789"
  },
  {
    id: 3,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Students playing as Donna and the Dynamos perform 'Super Trouper' while wearing student produced costumes in the fall production of Mamma Mia.",
    color: "Crimson"
  },
  {
    id: 4,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Students playing as Sam, Bill, and Harry perform 'Thank you for the Music' in the fall production of Mamma Mia.",
    color: "Crimson"
  },
  {
    id: 5,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Students playing as Sophie, Alli, and Lisa perform 'Honey Honey' in the fall production of Mamma Mia.",
    color: "Crimson"
  },
  {
    id: 6,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Angie playing as Donna is performing 'Money, Money, Money' in the fall production of Mamma Mia.",
    color: "Crimson"
  },
  {
    id: 7,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Mamma Mia cast performs 'Gimme Gimme Gimme'",
    color: "#789"
  },
  {
    id: 8,
    year: "Fall 2022",
    title: "Mamma Mia",
    description: "Students playing as Sam, Bill, and Harry rehearse the 'Waterloo' ending number while wearing student produced costumes in the fall production of Mamma Mia.",
    color: "#789"
  },
  {
    id: 9,
    year: "Spring 2022",
    title: "Moon Over Buffalo",
    description: "Crew Picture for Moon Over Buffalo after UIL One Act Play Competition. Students Shevelle and Jackie were awarded Best Technical Crew.",
    color: "#789"
  },
  {
    id: 10,
    year: "Spring 2022",
    title: "Moon Over Buffalo",
    description: "Casting Crew Picture for Moon Over Buffalo after UIL One Act Play Competition. Advances to Bi-District Award.",
    color: "#789"
  },
  {
    id: 11,
    year: "Fall 2021",
    title: "Once on this Island",
    description: "Students perform 'Human Heart' in Fall Prodictuion of Once on this Island. In the background, a free standing bridge which supported 5 actors at once, and was built by students.",
    color: "#789"
  },
  {
    id: 12,
    year: "Fall 2021",
    title: "Once on this Island",
    description: "Cast and Crew take a picture with elementary students after performance of Once on this Island for elementary and middle schools in the area.",
    color: "#789"
  },
  {
    id: 13,
    year: "Fall 2021",
    title: "Once on this Island",
    description: "Actors sign autographs for elementary students after performance of Once on this Island for elementary and middle schools in the area. All make up and costumes were done by students.",
    color: "#789"
  },
  {
    id: 14,
    year: "Spring 2021",
    title: "Alice in Wonderland",
    description: "Students perform full dress rehearsal of Alice in Wonderland. Students who decided to attend school during the COVID pandemic painted the backdrops and built the set.",
    color: "#789"
  },
  {
    id: 15,
    year: "Spring 2021",
    title: "Alice in Wonderland",
    description: "The White Queen showcasing her costume and makeup done by myself as her Technical Director.",
    color: "#789"
  },
  {
    id: 16,
    year: "Spring 2021",
    title: "Alice in Wonderland",
    description: "The cast of Alice in Wonderland showcasing their costumes and makeup after final performance of the year.",
    color: "#789"
  },
  {
    id: 17,
    year: "Spring 2021",
    title: "The Marriage of Bette and Boo",
    description: "Student painted backdrop for UIL One Act Play Competition 2021.",
    color: "#789"
  },
  {
    id: 18,
    year: "Spring 2021",
    title: "The Marriage of Bette and Boo",
    description: "Full dress rehearsal for UIL One Act Play Competition 2021. The cast advanced to District Competition during the COVID pandemic.",
    color: "#789"
  },
  {
    id: 19,
    year: "Spring 2020",
    title: "Blood Wedding",
    description: "Dress rehearsal for UIL One Act Play Competition 2020. Unfortunately, the competition was cut short due to the COVID pandemic.",
    color: "#789"
  },
  {
    id: 20,
    year: "Spring 2020",
    title: "Blood Wedding",
    description: "All Start Cast award recipients Samantha Vadillo and America Gonzalez.",
    color: "#789"
  },
  {
    id: 21,
    year: "Fall 2019",
    title: "Community Theater Mamma Mia",
    description: "Performance of Mamma Mia at Harbor Playhouse Community Theater in Corpus Christi, TX. I had the opportunity to make the costumes for the show.",
    color: "#789"
  },
  {
    id: 22,
    year: "Fall 2019",
    title: "Is There a Comic in the House?",
    description: "Fall production of 'Is There a Comic in the House?' that I had the opportunity to assist direct while student teaching at Flour Bluff High School.",
    color: "#789"
  },
  {
    id: 23,
    year: "Summer 2019",
    title: "Is There a Comic in the House?",
    description: "Students performing one of their weekly performances at Harbor Playhouse Community Theater for the Young People's Summer Theater Camp. I had the opportunity to teach the students the fundamentals of technical theatre as the head technical counselor.",
    color: "#789"
  },
  {
    id: 24,
    year: "Fall 2019",
    title: "Alice in Wonderland",
    description: "Mad Hatter performs in 'Alice in Wonderland as part of the Senior Capstone Project where I was the lighting director.",
    color: "#789"
  },
  {
    id: 25,
    year: "Fall 2019",
    title: "The Miraculous Journey of Edward Tulane",
    description: "Assistant Directed UIL One Act Play during my field base experience at Flour Bluff Middle School.",
    color: "#789"
  }
];